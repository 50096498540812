module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-preact@7.13.1_gatsby@5.13.3_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2.0_lfda757dimlygex5tyai6ok52a/node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.3_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_bdp5ieahxnzwu3r37wntccwqcu/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8RKDZ104E6"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-facebook-pixel@1.0.8/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"385520387174209"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2.0_react@18.2.0__react@18.2.0__f4bvkakplvd4mierhkwg3i4bii/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
