import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const BrandIconsSmall = () => {
  return (
    <div className="flex flex-row items-center justify-center space-x-7">
      <StaticImage
        src="../../images/brands/halal.png"
        alt="Trip Advisor"
        className="dropshadow_image"
        placeholder="blurred"
        height={80}
      />
      <StaticImage
        src="../../images/brands/alcohol.png"
        alt="Trip Advisor"
        className="dropshadow_image"
        placeholder="blurred"
        height={80}
      />
      <a
        href="https://www.tripadvisor.co.uk/Restaurant_Review-g186378-d27839247-Reviews-Regalia-Stoke_on_Trent_Staffordshire_England.html"
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          src="../../images/brands/trip-advisor.png"
          alt="Trip Advisor"
          className="dropshadow_image"
          placeholder="blurred"
          height={80}
        />
      </a>
    </div>
  );
};

const BrandIcons = ({ noPayments }) => {
  return (
    <div className="space-y-8 mb-4 flex flex-col items-center justify-center">
      <div className="flex flex-row items-center justify-center space-x-7">
        <StaticImage
          src="../../images/brands/halal.png"
          alt="Trip Advisor"
          className="dropshadow_image"
          placeholder="blurred"
          height={100}
        />
        <StaticImage
          src="../../images/brands/alcohol.png"
          alt="Trip Advisor"
          className="dropshadow_image"
          placeholder="blurred"
          height={100}
        />
        <a
          href="https://www.tripadvisor.co.uk/Restaurant_Review-g186378-d27839247-Reviews-Regalia-Stoke_on_Trent_Staffordshire_England.html"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../../images/brands/trip-advisor.png"
            alt="Trip Advisor"
            className="dropshadow_image"
            placeholder="blurred"
            height={100}
          />
        </a>
      </div>
      {/* <StaticImage
        src="../../images/brands/trust-pilot.png"
        alt="Trip Advisor"
        className="dropshadow_image"
        placeholder="blurred"
        height={50}
      /> */}
      {!noPayments && (
        <StaticImage
          src="../../images/brands/payment.png"
          alt="Trip Advisor"
          className="dropshadow_image"
          placeholder="blurred"
          height={55}
        />
      )}
    </div>
  );
};

export default BrandIcons;
