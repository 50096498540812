import React, { useRef, useCallback, useEffect } from "react";

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }));
  const throttleTimeout = useRef(null); // Use useRef for throttleTimeout

  // useCallback to memoize the callback function
  const callBack = useCallback(() => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout.current = null;
  }, [effect, element, useWindow]); // Add the relevant dependencies here

  // useLayoutEffect;
  useEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout.current === null) {
          throttleTimeout.current = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [callBack, wait]); // Use the static array with correct dependencies
}

export function useScrollTrigger({ element, threshold, reverse = false }) {
  const [isScroll, setIsScroll] = React.useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isScrolled = Math.abs(currPos.y) > threshold;
      setIsScroll(reverse ? !isScrolled : isScrolled);
    },
    [], // Include an empty dependency array if needed
    element
  );

  return isScroll;
}
