const topAnchorId = "back-to-top-anchor";
const uri = "http://192.168.0.56:8072";

export const urls = {
  home: "/",
  about: "/about/",
  menu: "/menu/",
  gallery: "/gallery/",
  contact: "/contact/",
  events: "/events/",
};

const pages = [
  {
    to: urls.home,
    // text:"Contact Us"
    // linkDisplay:
    // displayTitle: "About Us",
    title: "Home",
    description: "",
    // displayComponent: IntroContent,
  },
  {
    to: urls.about,
    title: "About", // - nav display
    description: "",
  },
  { to: urls.menu, title: "Menu", displayTitle: "Menu", layoutImage: "" },
  { to: urls.gallery, title: "Gallery", displayTitle: "Gallery" },
  {
    to: urls.contact,
    title: "Contact Us",
    displayTitle: "Contact",
    layoutImage: "",
  },
  {
    to: urls.events,
    title: "Events",
    displayTitle: "Events",
    // layoutImage: "front",
  },
  //   {
  //     to: urls.portfolio,
  //     // text:"Contact Us"
  //     // linkDisplay:
  //     // displayTitle: "About Us",
  //     title: "Portfolio",
  //     description: "",
  //     layoutImage: "10",
  //     displayTitle: "A Space of Magnificence",
  //     displaySubtitle: "Explore our Work - The possibilities are endless!",
  //     sectionProps: { customMobileNavPadding: "pb-0" },
  //     displayComponent: IntroContent,
  //     displayComponentProps: {
  //       shortlist: true,
  //       hideAction: true,
  //       useLinks: true,
  //     },
  //   },
  //   {
  //     to: urls.services,
  //     title: "Services",
  //     displayTitle: "Our Services",
  //     displaySubtitle: "Custom Built & Designed to your Specification",
  //     layoutImage: "3",
  //   },
  //   {
  //     to: urls.contact,
  //     title: "Contact",
  //   },
];

const constants = {
  uri: uri,
  gqlUri: `${uri}/graphql`,
  formatMediaUrl: (url) => `${uri}/media/${url}`,
  topAnchorId,

  genTopAnchorLink: (path) => `${path ?? ""}#${topAnchorId}`,
  navbarOffset: -64,
  navPadding: 64,
  seo: Object.assign(
    {},
    ...pages.map(({ to, ...x }) => ({ [to]: { ...x, ...to } }))
  ),
  navLinks: pages
    .filter((p) => !p?.hideFromMainNav)
    .map((page) => ({
      to: page.to,
      title: page.title,
      text: page.linkDisplay ?? page.title,
    })),
  navDrawerLinks: pages
    // .filter(p => !p?.hideFromMainNav)
    .map((page) => ({
      to: page.to,
      title: page.title,
      text: page.linkDisplay ?? page.title,
    })),
};
export default constants;
